<template>
  <v-list>
    <v-list-item
        class="nav-list-item"
        v-on:click="onPanelChanged('Home')">
      <div class="container">
        <p class="nav_title">Start</p>
        <div class="img-container">
          <v-img class="nav-img" src="@/assets/start.svg"/>
        </div>
      </div>
    </v-list-item>

    <v-list-item
        class="nav-list-item"
        v-on:click="onPanelChanged('Browse')">
      <div class="container">
        <p class="nav_title">Stöbern</p>
        <div class="img-container">
          <v-img class="nav-img" src="@/assets/browse.svg"/>
        </div>
      </div>
    </v-list-item>

    <v-list-item
        class="nav-list-item"
        v-on:click="onPanelChanged('Overview')">
      <div class="container">
        <p class="nav_title">Verzeichnis</p>
        <div class="img-container">
          <v-img class="nav-img" src="@/assets/overview.svg"/>
        </div>
      </div>
    </v-list-item>

    <v-list-item
        class="nav-list-item"
        v-on:click="onPanelChanged('About')">
      <div class="container">
        <p class="nav_title">Über mich</p>
        <div class="img-container">
          <v-img class="nav-img" src="@/assets/about.svg"/>
        </div>
      </div>
    </v-list-item>

  </v-list>

</template>

<script>
export default {
  name: "SideBar",
  data(){
    return{
      nav_items: [
        {title: "Start", icon: "mdi-clock", link: "Home"},
        {title: "Stöbern", icon: "mdi-movie-roll", link: "Browse"},
        {title: "Verzeichnis", icon: "mdi-pen", link: "Overview"},
        {title: "Über mich", icon: "mdi-flag", link: "About"},
      ]
    }
  },
  methods:{
    onPanelChanged(sel) {
      this.$router.push({name:sel});
    },
  }
}
</script>