<template>
<v-img id="title-img" src="@/assets/title_h2.svg"/>

  <v-row no-gutters>
  <v-col :lg="2" cols="0"></v-col>

  <v-col :lg="8" cols="12">
    <ReviewCard v-for="review in reviews" :key="review.Id" v-bind:review="review" ></ReviewCard>
  </v-col>

  <v-col :lg="2" cols="0"></v-col>
  </v-row>
  <div class="text-center" style="margin-bottom: 50px">
  <v-progress-circular
      v-if="!this.endOfBlog"
      style
      :size="50"
      color="var(--first-color)"
      indeterminate
      rounded
      height="6"
      ref="lb"
  ></v-progress-circular>
  </div>
  <div v-if="this.endOfBlog" cols="12" class="text-center" style="margin-bottom: 100px">
    <svg  v-for="n in 5" v-bind:key="n" width="50px" height="50px" viewBox="0 0 32 32" :class="5 >= n ? 'black' : 'gray'">
      <path d="M30.728 18.612l-2.112-0.697 0.050 0.052-11.683 4.24-11.184-11.823-2.745-0.906c-1.386 0.981-1.541 3.774-0.61 4.746l13.805 14.19 14.602-5.228c-1.33-0.727-2.409-2.796-0.123-4.573zM15.474 22.441l-11.504-11.928h0.344l11.453 11.693-0.294 0.235zM16.353 27.987c0 0-1.592-1.86 0.471-4.334l12.501-4.527c0 0-1.438 2.469 0.245 3.927l-13.217 4.935zM5.799 10.384l-0.382-0.404 11.654-4.138 11.544 12.073 2.112 0.697c-0.010 0.008-0.020 0.016-0.030 0.024l0.246-0.088-13.623-14.125-14.212 5.072 2.69 0.888z"></path>
    </svg>
  </div>
</template>

<style scoped>
.black {
  fill: var(--first-color);
}
.gray {
  fill: var(--forth-color);
}
</style>

<script>
import ReviewCard from "@/components/ReviewCard";
import axios from "axios";
export default {
  name: 'HomeView',
  components: {ReviewCard},
  data(){
    return {
      loading: false,
      endOfBlog: false,
      reviews_id:[],
      reviews: [],
    }
  },
  methods:{
    checkVisible() {
      if(this.$refs.lb == null){ return;}
      var rect = this.$refs.lb.$el.getBoundingClientRect();
      var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    },
    handleScroll () {
      if(this.checkVisible()){
        this.loadMore();
      }
    },
    loadMore(){
      if(this.loading || this.endOfBlog || this.reviews_id.length == 0){
        return;
      }
      this.loading = true;
      let id_to_retrieve = [];

      for (let i = 0; i < this.reviews_id.length; i++) {
        let alreadyloaded = false;
        for (let j = 0; j < this.reviews.length; j++){
          if (this.reviews[j]['Id'] == this.reviews_id[i]){
            alreadyloaded = true;
            break;
          }
        }
        if (!alreadyloaded){
          id_to_retrieve.push(this.reviews_id[i]);
        }

        if (id_to_retrieve.length >= 5){ //load 5 review at max
          break;
        }
      }

      var bodyFormData = new FormData();
      bodyFormData.append('id', JSON.stringify(id_to_retrieve));


      axios({
        method: "post",
        url: "../api/review_for_homeview.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
          .then(res => {
            res.data.forEach((item)=>{this.reviews.push(item[0]);});
            this.loading = false;
            this.endOfBlog = this.reviews_id.length == this.reviews.length;
          })
          .catch(function (response) {
            //handle error
            console.log(response);
            this.loading = false;
          });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {

    fetch("api/reviews_ids.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      }
    })
        .then(response => response.json()
            .then(data => ({
              data: data,
              status: response.status
            }))
            .then(res => {
              res.data.forEach((item)=>{this.reviews_id.push(item["Id"])});
              this.loadMore();
            })
        );
  },
}


</script>