<template>
  <div class="review-card" v-on:click="toReview()">

    <span v-for="(author, index) in review.authors" :key="author.Id">
      <span class="authorLink" @click.stop="toAuthor(author.Id)">{{ author.FirstName }} {{ author.LastName }} </span>
      <span v-if="index < review.authors.length - 1">, </span>
    </span>

    -
    <span v-for="(genre, index) in review.genres" :key="genre.Id">
      <span class="genreLink" @click.stop="toGenre(genre.Id)">{{ genre.Name }}</span>
      <span v-if="index < review.genres.length - 1">, </span>
    </span>

    <h1>{{review.Title}}</h1>

    <div style="width: 100%; height:3px; background-color: var(--first-color); margin:auto"></div>
    <v-row no-gutters style="margin-top: 20px">
      <v-col cols="2"/>
      <v-col cols="8">
        <img style="width: 100%; margin-bottom: 20px" ref="reviewimage"/>
      </v-col>
      <v-col cols="2"/>
      <v-col cols="12">
        {{shortened_plain_text}}...
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: "ReviewCard",
  props: {
    review: null,
  },
  computed:{
    shortened_plain_text: {
      get(){
        return this.review.PlainText.split(/\s+/).slice(0, 30).join(" ");

      }
    }
  },
  methods:{
    toReview(){
      console.log(this.review.Id, this.review.image);
      this.$router.push({name:"ReviewView", params:{id:this.review.Id}});
    },
    toAuthor(id){
      this.$router.push({name:"AuthorView", params:{id:id}});
    },
    toGenre(id){
      this.$router.push({name:"GenreView", params:{id:id}});
    },
  },
  mounted() {
    if(this.review.image != null){
      this.$refs.reviewimage.src = this.review.image;
    }
  }
}
</script>

<style scoped>

</style>