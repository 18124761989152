<template>
  <LayoutTemplate>
    <v-img style="margin-bottom: 80px" src="@/assets/nini.jpg"/>

    <p style="text-align: justify">
      Herzlich Willkommen in meiner Welt der Bücher. Schön, dass du auf meinen Blog gefunden hast. <br>
      Kennst du das Gefühl, ein Buch nicht weglegen zu können, weil es einfach so gut ist? Wenn ja, dann bist du hier genau richtig.<br><br>
      Bücher verfolgen mich schon seit meiner Kindheit. Als ich noch nicht lesen konnte, blätterte ich leidenschaftlich gerne in Bilderbüchern und sah mir die Zeichnungen darin wieder und wieder an. Etwas später verschlang ich dank meinem Bibliotheksausweis sämtliche Bücher von Federica de Cesco, Thomas Brezina und Jana Frey. Und noch etwas später entdeckte ich Carlos Ruiz Zafón, deren Worte mich noch heute jedes Mal aufs Neue verzaubern und mich in eine Welt entführen, die ich am liebsten nicht mehr verlassen möchte.<br><br>
      Auch in meinem Berufsleben blättere ich fleissig in Büchern, dessen Inhalte jedoch trocknerer Natur sind – nämlich in Gesetzesbüchern. Zudem bin ich Mama von zwei kleinen Bücherwürmern und liebe es, ihnen Geschichten vorzulesen.<br><br>
      Auf diesem Blog stelle ich dir meine absoluten Herzensbücher vor und du findest Rezensionen und Leseempfehlungen für Jung und Alt. Ab und zu wird es vielleicht sogar eine Empfehlung zu einem Hörbuch oder einem Podcast hierhin schaffen.<br><br>
      Ich hoffe, dass dich diese Seite für künftigen Lesestoff inspiriert und du hier den ein oder anderen Geheimtipp finden kannst.<br><br>
      Hinterlasse mir gerne einen Kommentar. <a href="mailto:n@gutbebucht.ch">Ich freue mich von dir zu hören.</a><br><br>
      Herzlichst,<br>
      N.
    </p>

  </LayoutTemplate>
</template>

<script>
import LayoutTemplate from "@/components/LayoutTemplate.vue";

export default {
  name: "AboutMe",
  components: {LayoutTemplate}
}
</script>

<style scoped>

</style>