<template>
  <LayoutTemplate :title="genre_info.Name" subtitle="Genre">
    <ReviewCard v-for="review in genre_reviews" :key="review.Id" v-bind:review="review"></ReviewCard>
  </LayoutTemplate>
</template>

<script>
import axios from "axios";
import ReviewCard from "@/components/ReviewCard";
import LayoutTemplate from "@/components/LayoutTemplate.vue";

export default {
  name: "GenreView",
  components: {ReviewCard, LayoutTemplate},
  data(){
    return{
      genre_id: null,
      genre_reviews: [],
      genre_review_ids: [],
      genre_info: {Name:""},
    }
  },
  created() {
    this.genre_id = this.$route.params.id;

    this.genre_info = {Name:""};
    this.genre_review_ids = [];
    this.genre_reviews = [];

    var bodyFormData = new FormData();
    bodyFormData.append('id', this.genre_id);

    axios({
      method: "post",
      url: "../api/genre_info.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
        .then(res => {
          this.genre_info = res.data[0];

        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });


    axios({
      method: "post",
      url: "../api/reviews_for_genre.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
        .then(res => {
          res.data.forEach((item)=>{this.genre_review_ids.push(item["Id"])});

          var bodyFormData = new FormData();
          bodyFormData.append('id', JSON.stringify(this.genre_review_ids));

          axios({
            method: "post",
            url: "../api/review_for_homeview.php",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          })
              .then(res => {
                res.data.forEach((item)=>{this.genre_reviews.push(item[0]);});
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });

  }
}
</script>

<style scoped>

</style>