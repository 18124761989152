<template>
  <v-app dense overflow-hidden>
    <v-app-bar
        v-if="!isLg"
        id="app-bar"
        prominent
        shrink-to-scroll
    >
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-img src="@/assets/logo_short.svg" style="height: 80%; cursor: pointer;" @click.stop="toHome()"/>

    </v-app-bar>
    <v-navigation-drawer
        class="hidden-lg-and-up"
        v-model="drawer"
        location="left"
        temporary
    >
      <SideBar id="nav-list-mobile"></SideBar>
    </v-navigation-drawer>


    <v-main>

        <v-row id="mainAppRow" no-gutters>
          <v-col id="firstcolumn" class="hidden-md-and-down" :lg="1" :md="0"></v-col>
          
          <v-col id="secondcolumn" class="hidden-md-and-down" :lg="2" :md="0">
              <v-img style="top:20px; position: sticky;align-self: start; margin-right: 20px; cursor: pointer" id="logo" src="@/assets/logo.svg" @click.stop="toHome()" />


              <SideBar id="nav-list"></SideBar>
          </v-col>
          <v-col id="thirdcolumn" :lg="8" :md="12" :sm="12">
            <router-view v-slot="{ Component }">
              <keep-alive :include="['HomeView', 'OverviewView', 'BrowseView']">
                <component :is="Component"  :key="$route.fullPath"/>
              </keep-alive>
            </router-view>
          </v-col>
          <v-col id="forthcolumn" class="hidden-md-and-down"  :lg="1" :md="0"/>
        </v-row>

    </v-main>
  </v-app>
</template>

<script>
import SideBar from "./components/SideBar"

export default {
  name: 'App',
  components: {
    SideBar
  },
  data: () => ({
    drawer: false,
    isLg:false,
  }),
  mounted() {
    this.resizeHandler();
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      this.isLg = window.innerWidth > 1264;
    },
    toHome(){
      this.$router.push({name:"Home"});
    },
  },
  watch: {
    group () {
      this.drawer = false
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
