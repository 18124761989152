
import { createApp } from "vue";
import {createRouter, createWebHistory} from "vue-router";
import routes from './routes';
import App from './App.vue'
import './assets/style.css';
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'


const app = createApp(App);

const routers=createRouter ({
    mode: history,
    base: '/',
    history: createWebHistory(),
    routes,
})


app.use(routers);
app.use(vuetify);

loadFonts();

app.mount('#app');