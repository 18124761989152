<template>
  <v-row class="review-view-layout" no-gutters>
    <v-col :lg="2" cols="0"/>
    <v-col :lg="8" cols="12">
      <img style="width:100%" ref="reviewimage" :src="require(`@/assets/placeholder.png`)"/><br><br>

      <span class="date">Verfasst am {{review.Date}}</span><br>

      <h1>{{review.Title}}</h1>
      <span v-for="(author, index) in review.authors" :key="author.Id">
        <span class="authorLink" @click="toAuthor(author.Id)">{{ author.FirstName }} {{ author.LastName }}</span>
        <span v-if="index < review.authors.length - 1">, </span>
  </span>
      -
      <span v-for="(genre, index) in review.genres" :key="genre.Id">
        <span class="genreLink" @click.stop="toGenre(genre.Id)">{{ genre.Name }}</span>
        <span v-if="index < review.genres.length - 1">, </span>
  </span>
      <br>

      <br>
      <p><span v-html="review.HTMLText"></span></p>
      <br>
      <br>
      <div class="text-center">
        <svg v-for="n in 5" v-bind:key="n" width="50px" height="50px" viewBox="0 0 32 32" :class="review.Evaluation >= n ? 'black' : 'gray'">
          <path d="M30.728 18.612l-2.112-0.697 0.050 0.052-11.683 4.24-11.184-11.823-2.745-0.906c-1.386 0.981-1.541 3.774-0.61 4.746l13.805 14.19 14.602-5.228c-1.33-0.727-2.409-2.796-0.123-4.573zM15.474 22.441l-11.504-11.928h0.344l11.453 11.693-0.294 0.235zM16.353 27.987c0 0-1.592-1.86 0.471-4.334l12.501-4.527c0 0-1.438 2.469 0.245 3.927l-13.217 4.935zM5.799 10.384l-0.382-0.404 11.654-4.138 11.544 12.073 2.112 0.697c-0.010 0.008-0.020 0.016-0.030 0.024l0.246-0.088-13.623-14.125-14.212 5.072 2.69 0.888z"></path>
        </svg>
      </div>
    </v-col>
    <v-col :lg="2" cols="0"/>
    </v-row>
</template>

<style scoped>
.black {
  fill: var(--first-color);
}
.gray {
  fill: var(--forth-color);
}
</style>

<script>
import axios from 'axios'
export default {
  name: "ReviewView",
  data: function () {
    return {
      review_id: null,
      review: [],
    };
  },
  methods:{
    toAuthor(id){
      this.$router.push({name:"AuthorView", params:{id:id}});
    },
    toGenre(id){
      this.$router.push({name:"GenreView", params:{id:id}});
    }
  },
  unmounted() {
    this.$refs.reviewimage.src = "require(`@/assets/placeholder.png`)";
    this.review = null;
  },

  created() {
    this.review_id = this.$route.params.id;

    var bodyFormData = new FormData();
    bodyFormData.append('id', this.review_id);

    axios({
      method: "post",
      url: "../api/review.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
        .then(res => {
          console.log(res);

          this.review = res.data[0];

          var bodyFormData2 = new FormData();
          bodyFormData2.append('image', this.review.ImagePath);

          axios({
            method: "post",
            url: "../api/image.php",
            data: bodyFormData2,
            headers: { "Content-Type": "multipart/form-data" },
          })
              .then(res => {
                console.log(res);
                this.$refs.reviewimage.src = res.data;
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });

        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });

    console.log(this.review);

  },
}
</script>

<style scoped>

</style>