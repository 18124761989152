<template>
  <LayoutTemplate title="Durchsuche alle meine Rezensionen">
    <div class="d-flex">
      <v-text-field dense v-model="search_term" @keydown.enter="onSubmit()" label="Suche nach ..."></v-text-field>
      <!--        <v-btn @click="onSubmit()">Los</v-btn>-->
    </div>

    <v-row v-if="!this.init" no-gutters>
      <v-col :lg="6" cols="12" >
        <div style="display: flex; justify-content: flex-end; min-height: 60px;">
            <div class="background">Autor*innen</div>
            <div v-if="!this.loading_authors" class="content-browse">
              {{number_of_found_authors}}
            </div><v-progress-circular
            class="content-browse"
            v-if="this.loading_authors"
            style
            :size="20"
            color="var(--first-color)"
            indeterminate
            rounded
            height="6"
        ></v-progress-circular>

        </div>
        <div style="display: flex; flex-direction: column; justify-content: flex-end">
          <div v-for="author in authors" :key="author.Id">
            <span style="float:right" class="authorLink" @click.stop="toAuthor(author.Id)">{{ author.LastName }}, {{ author.FirstName }}</span>
          </div>
        </div>
      </v-col>
      <v-col :lg="6" cols="12">
        <div style="display: flex; justify-content: flex-end; min-height: 60px;">
          <div class="background">Genres</div>
          <div v-if="!loading_genres" class="content-browse">
            {{number_of_found_genres}}
          </div>
          <v-progress-circular
              class="content-browse"
              v-if="this.loading_genres"
              style
              :size="20"
              color="var(--first-color)"
              indeterminate
              rounded
              height="6"
          ></v-progress-circular>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: flex-end">
          <div v-for="genre in genres" :key="genre.Id">
            <span style="float:right"  class="genreLink" @click.stop="toGenre(genre.Id)">{{ genre.Name }}</span>
          </div>
          </div>

      </v-col>
    </v-row>
    <div v-if="!this.init" style="display: flex; justify-content: flex-end; min-height: 60px;">
      <div class="background">Rezensionen</div>
      <div v-if="!this.loading_reviews" class="content-browse">
        {{number_of_found_reviews}}
      </div>
      <v-progress-circular
          class="content-browse"
          v-if="this.loading_reviews"
          style
          :size="20"
          color="var(--first-color)"
          indeterminate
          rounded
          height="6"
      ></v-progress-circular>
    </div>
    <v-col v-for="review in reviews" :key="review.Id">
      <ReviewCard v-bind:review="review"></ReviewCard>
    </v-col>
  </LayoutTemplate>
</template>

<script>
import axios from "axios";
import ReviewCard from "@/components/ReviewCard";
import LayoutTemplate from "@/components/LayoutTemplate.vue";

export default {
  name: "BrowseView",
  components: {ReviewCard, LayoutTemplate},
  data: () => ({
    search_term: null,
    review_ids: [],
    reviews: [],
    authors: [],
    genres: [],
    number_of_found_authors: "",
    number_of_found_genres: "",
    number_of_found_reviews: "",
    loading_genres: false,
    loading_authors: false,
    loading_reviews: false,
    init: true,
  }),
  computed: {
    reviews_length: function(){
      if (this.review_ids == null){
        return 0;
      }
      return this.review_ids.length;
    },
  },
  methods: {
    onSubmit () {
      var bodyFormData = new FormData();
      bodyFormData.append('search_term', this.search_term);

      this.review_ids = [];
      this.reviews = [];

      this.loading_genres = true;
      this.loading_authors = true;
      this.loading_reviews = true;

      this.init = false;

      axios({
        method: "post",
        url: "../api/search.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
          .then(res => {
            console.log(res);
            res.data.forEach((item)=>{this.review_ids.push(item["Id"])});
            this.number_of_found_reviews = this.getResultMessage(this.reviews_length);

            var bodyFormData = new FormData();
            bodyFormData.append('id', JSON.stringify(this.review_ids));


            axios({
              method: "post",
              url: "../api/review_for_homeview.php",
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            })
                .then(res => {
                  res.data.forEach((item)=>{this.reviews.push(item[0]);});
                  this.loading_reviews = false;
                })
                .catch(function (response) {
                  //handle error
                  console.log(response);
                });
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });

      axios({
        method: "post",
        url: "../api/search_author.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
          .then(res => {
            console.log(res);
            this.authors = res.data;
            this.number_of_found_authors = this.getResultMessage(this.authors.length);
            this.loading_authors = false;
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });

      axios({
        method: "post",
        url: "../api/search_genre.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
          .then(res => {
            console.log(res);
            this.genres = res.data;
            this.number_of_found_genres = this.getResultMessage(this.genres.length);
            this.loading_genres = false;
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });

    },


    toAuthor(id){
      this.$router.push({name:"AuthorView", params:{id:id}});
    },
    toGenre(id){
      this.$router.push({name:"GenreView", params:{id:id}});
    },
    getResultMessage(n){
      var result = "Ergebnisse";
      if (n == 1){
        result = "Ergebnis";
      }
      return n + ' ' + result;
    },
  },
}
</script>

<style scoped>

</style>