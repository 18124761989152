import HomeView from './components/HomeView.vue'
import AboutMe from './components/AboutMe'
import ReviewView from "@/components/ReviewView";
import AuthorView from "@/components/AuthorView";
import BrowseView from "@/components/BrowseView";
import GenreView from "@/components/GenreView";
import OverviewView from "@/components/OverviewView.vue";
import NotFound from "@/components/NotFound.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/browse',
        name: 'Browse',
        component: BrowseView,
    },
    {
        path: '/overview',
        name: 'Overview',
        component: OverviewView,
    },
    {
        path: '/aboutme',
        name: 'About',
        component: AboutMe,
    },
    {
        path: '/review/:id',
        props: true,
        name: 'ReviewView',
        component: ReviewView
    },
    {
        path: '/author/:id',
        props: true,
        name: 'AuthorView',
        component: AuthorView
    },
    {
        path: '/genre/:id',
        props: true,
        name: 'GenreView',
        component: GenreView
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },

];

export default routes;