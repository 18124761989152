<template>
  <v-row class="template-layout" no-gutters>
    <v-col :lg="2" cols="0"/>
    <v-col :lg="8" cols="12">
      <p class="site-subtitle">{{subtitle}}</p>
      <h1 class="site-title">{{title}}</h1>
      <slot />
    </v-col>
    <v-col :lg="2" cols="0"/>
  </v-row>
</template>

<script>
export default {
  name: "LayoutTemplate",
  props: ['title', 'subtitle'],
}
</script>

<style scoped>

</style>