<template>
  <LayoutTemplate title="Verzeichnis aller Rezensionen" style="display: flex;">

    <div class="d-flex align-center flex-column">
      Sortieren nach
      <v-btn-toggle
          rounded="0"
          mandatory
          class="toggle-group"
          v-model="toggle_exclusive">
        <v-btn :class="{'selected-sort-button' : toggle_exclusive == 0}" class="overviewbutton">
          Autor*in
        </v-btn>
        <v-btn :class="{'selected-sort-button' : toggle_exclusive == 1}" class="overviewbutton">
          Genre
        </v-btn>
        <v-btn :class="{'selected-sort-button' : toggle_exclusive == 2}" class="overviewbutton">
          Titel
        </v-btn>
        <v-btn :class="{'selected-sort-button' : toggle_exclusive == 3}" class="overviewbutton">
          Bewertung
        </v-btn>
      </v-btn-toggle>
    </div>

    <div style="float:none; margin:auto; width: fit-content;">
      <div v-for="group in entry_groups" :key="group.letter" style="margin-bottom: 20px;">
        <div class="background-overview">{{group.letter}}</div>
        <div>
          <div v-for="entry in group.elements" :key="[entry.review_id, entry.author_id]">
            <span v-if="toggle_exclusive==0" v-on:click="toReview(entry.review_id)" class="genreLink">
              {{entry.author_lastname}}, {{entry.author_firstname}} - {{entry.review_title}}
            </span>
            <span v-if="toggle_exclusive==1" v-on:click="toReview(entry.review_id)" class="genreLink">
              {{entry.review_title}}
            </span>
            <span v-if="toggle_exclusive==2" v-on:click="toReview(entry.review_id)" class="genreLink">
              {{entry.review_title}}
            </span>
            <span v-if="toggle_exclusive==3" v-on:click="toReview(entry.review_id)" class="genreLink">
              {{entry.review_title}}
            </span>
          </div>
        </div>
      </div>
    </div>


  </LayoutTemplate>
</template>

<script>
import axios from "axios";
import LayoutTemplate from "@/components/LayoutTemplate.vue";

class Group {
  constructor(letter) {
    this.letter = letter;
    this.elements = [];
  }
}

export default {
  name: "OverviewView",
  components: {LayoutTemplate},
  data(){
    return{
      entries:[],
      toggle_exclusive: null,
      entry_groups: [],
    }
  },
  methods:{
    toReview(review_id){
      this.$router.push({name:"ReviewView", params:{id:review_id}});
    },
  },
  watch: {
    toggle_exclusive: function (newValue) {

      function unique(a, fn) {
        if (a.length === 0 || a.length === 1) {
          return a;
        }
        if (!fn) {
          return a;
        }

        const result = [];

        for (let i = 0; i < a.length; i++) {
          let isUnique = true;
          for (let j = i + 1; j < a.length; j++) {
            if (fn(a[i], a[j])) {
              isUnique = false;
              break;
            }
          }
          if (isUnique) {
            result.push(a[i]);
          }
        }

        return result;
      }

      if(newValue == 0){
        console.log("sortedarray case 0");
        console.log(this.entries);
        const unique_entries = unique(
            this.entries,
            (a,b) => a.review_id == b.review_id && a.author_id == b.author_id
        );
        console.log(unique_entries);

        unique_entries.sort((a, b) => {
          const nameA = a.author_lastname.toUpperCase(); // ignore upper and lowercase
          const nameB = b.author_lastname.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          if(a.review_title.toUpperCase() < b.review_title.toUpperCase()){
            return -1;
          }else if(a.review_title.toUpperCase() > b.review_title.toUpperCase()){
            return 1;
          }
          return 0;
        });

        this.entry_groups = [];
        for (let i = 0; i < unique_entries.length; i++) {
          let first_letter = Array.from(unique_entries[i].author_lastname.toUpperCase())[0];
          let current_group = null;
          for (let j = 0; j < this.entry_groups.length; j++) {
            if(this.entry_groups[j].letter == first_letter){
              current_group = this.entry_groups[j];
              break;
            }
          }
          if (current_group == null){
            current_group = new Group(first_letter);
            this.entry_groups.push(current_group);
          }
          current_group.elements.push(unique_entries[i]);
        }
      }
      else if(newValue == 1){

        const unique_entries = unique(
            this.entries,
            (a, b) => (a.review_id === b.review_id) & (a.genre_id === b.genre_id)
        ).slice();

        unique_entries.sort((a, b) => {
          const nameA = a.genre_name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.genre_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          if(a.review_title.toUpperCase() < b.review_title.toUpperCase()){
            return -1;
          }else if(a.review_title.toUpperCase() > b.review_title.toUpperCase()){
            return 1;
          }
          return 0;
        });

        this.entry_groups = [];
        for (let i = 0; i < unique_entries.length; i++) {
          let first_letter = unique_entries[i].genre_name;
          let current_group = null;
          for (let j = 0; j < this.entry_groups.length; j++) {
            if(this.entry_groups[j].letter == first_letter){
              current_group = this.entry_groups[j];
              break;
            }
          }
          if (current_group == null){
            current_group = new Group(first_letter);
            this.entry_groups.push(current_group);
          }
          current_group.elements.push(unique_entries[i]);
        }
      }
      else if(newValue == 2){

        const unique_entries = unique(
            this.entries,
            (a, b) => (a.review_id === b.review_id)
        );

        unique_entries.sort((a, b) => {
          const nameA = a.review_title.toUpperCase(); // ignore upper and lowercase
          const nameB = b.review_title.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });

        this.entry_groups = [];
        for (let i = 0; i < unique_entries.length; i++) {
          let first_letter = Array.from(unique_entries[i].review_title.toUpperCase())[0];
          let current_group = null;
          for (let j = 0; j < this.entry_groups.length; j++) {
            if(this.entry_groups[j].letter == first_letter){
              current_group = this.entry_groups[j];
              break;
            }
          }
          if (current_group == null){
            current_group = new Group(first_letter);
            this.entry_groups.push(current_group);
          }
          current_group.elements.push(unique_entries[i]);
        }


      }
      else if(newValue == 3){
        const unique_entries = unique(
            this.entries,
            (a, b) => (a.review_id === b.review_id)
        );

        unique_entries.sort((a, b) => {
          const nameA = a.review_evaluation;
          const nameB = b.review_evaluation;
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          if(a.review_title.toUpperCase() < b.review_title.toUpperCase()){
            return -1;
          }else if(a.review_title.toUpperCase() > b.review_title.toUpperCase()){
            return 1;
          }
          return 0;
        });

        this.entry_groups = [];
        for (let i = 0; i < unique_entries.length; i++) {
          let current_group = null;
          for (let j = 0; j < this.entry_groups.length; j++) {
            if(this.entry_groups[j].letter == unique_entries[i].review_evaluation){
              current_group = this.entry_groups[j];
              break;
            }
          }
          if (current_group == null){
            current_group = new Group(unique_entries[i].review_evaluation);
            this.entry_groups.push(current_group);
          }
          current_group.elements.push(unique_entries[i]);
        }


      }
    }
  },
  created() {

    axios({
      method: "post",
      url: "../api/data_for_overview.php",
      headers: { "Content-Type": "multipart/form-data" },
    })
        .then(res => {
          console.log(res);
          this.entries = res.data[0];
          this.toggle_exclusive = 0;
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });

  }
}
</script>

<style scoped>

</style>